<template>
  <div
    class="live-video flex"
    id="live-video"
    ref="live_video"
    @dblclick="fullScreen = !fullScreen"
  >
    <!-- tcplayer容器 -->
    <div
      class="video"
      :id="videoId"
      v-if="$online"
      style="
        background-color: #333333;
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
      "
    ></div>
    <!--liveqing的iframe-->
    <div
      v-else-if="liveNvrUrl != ''"
      style="width: 100%; height: 100%; aspect-ratio: 16/9"
    >
      <iframe
        :src="liveNvrUrl"
        width="100%"
        height="100%"
        allowfullscreen
        allow="autoplay; fullscreen"
      ></iframe>
    </div>
    <!-- flv.js容器 -->
    <video
      class="video"
      :id="videoId"
      autoplay
      v-else
      style="object-fit: contain; width: 100%; height: 100%; aspect-ratio: 16/9"
    ></video>

    <Spin size="large" fix v-if="spinShow"></Spin>
    <Icon
      type="md-share"
      v-if="$online && showCtrl && !fullScreen"
      class="share"
      @click="shareModel = true"
      title="分享"
    />
    <!-- 云台 -->
    <div class="control" @dblclick.stop v-if="showCtrl">
      <div class="four-direction">
        <div
          class="center-circle pointer"
          title="云台回中1"
          @click="remoteControlHandler(11)"
        ></div>
        <div class="center-line rotate-clockwise"></div>
        <div class="center-line rotate-anti-clockwise"></div>
        <!--控制左摇杆上下左右-->
        <div
          class="control-direction-arrow icon-top flex pointer"
          @click="remoteControlHandler(7, 'up')"
        >
          <Icon type="md-arrow-dropup" />
        </div>
        <div
          class="control-direction-arrow icon-right flex pointer"
          @click="remoteControlHandler(7, 'right')"
        >
          <Icon type="md-arrow-dropup" />
        </div>
        <div
          class="control-direction-arrow icon-bottom flex pointer"
          @click="remoteControlHandler(7, 'down')"
        >
          <Icon type="md-arrow-dropup" />
        </div>
        <div
          class="control-direction-arrow icon-left flex pointer"
          @click="remoteControlHandler(7, 'left')"
        >
          <Icon type="md-arrow-dropup" />
        </div>
      </div>
      <div class="zoom">
        <Icon
          type="md-add-circle"
          color="#212121"
          @click="remoteControlHandler(10)"
          title="焦段放大"
          size="30"
        />
        <br />
        <Icon
          type="md-refresh-circle"
          color="#212121"
          @click="remoteControlHandler(8)"
          title="焦段复位"
          size="30"
        />
        <br />
        <Icon
          type="md-remove-circle"
          size="30"
          @click="remoteControlHandler(9)"
          title="焦段缩小"
          color="#212121"
        />
      </div>
    </div>

    <img
      class="FullscreenIcon"
      v-if="showCtrl"
      :src="fullScreen ? videoScreenRestore : videoFullscreen"
      title="全屏播放"
      @click="fullScreen = !fullScreen"
    />
    <!-- 独立小窗independentLive -->
    <img
      class="FullscreenIcon"
      :src="videoIndependent"
      style="right: 100px; width: 30px"
      v-show="$online"
      v-if="$route.path != '/live/live-lists' && !fullScreen"
      title="小窗播放"
      @click="toIndependent"
    />
    <Modal v-model="shareModel" width="450" title="分享链接">
      <div v-if="$CSXF" style="margin:10px 0">
        <RadioGroup v-model="radioNum" @on-change="radioNumBut">
          <Radio label="0"  >链接/二维码分享</Radio>
          <Radio label="1" >短信分享</Radio>
        </RadioGroup>
      </div>
      <div class="shareUrl" v-if="radioNum==0">
        <p style="margin-bottom: 0.2rem">
          <span class="share-span" style="width: 370px">
            链接：<a :href="shareUrl" target="_blank">{{ shareUrl }}</a>
          </span>
          <Icon
            type="ios-copy"
            title="点击复制"
            style="cursor: pointer; float: right"
            size="30"
            @click="copy"
          />
        </p>
        <vue-qr
          :text="shareUrl"
          colorDark="#000"
          colorLight="#fff"
          :margin="5"
          style="vertical-align: bottom; margin-right: 0.2rem"
          :size="120"
        ></vue-qr>
        <span class="share-span" @click="bigqr = true;shareModel=false" style="cursor: pointer">点击放大</span>
<!--        <span class="share-note" @click="showNote" v-if="$CSXF">短信分享</span>-->
      </div>
      <div class="shareUrl" v-else>
        <span>手机号：</span>
        <Input v-model="phoneNum" class="note-input" placeholder="请输入手机号"/>
        <br/>
      </div>
      <div slot="footer" class="footer">
        <Button type="primary"  v-if="$CSXF"  @click="compile_ok">确认</Button>
        <Button @click="closeModl">取消</Button>
      </div>
    </Modal>
    <Modal v-model="shareNote">
      <span>手机号11111：</span>
      <Input v-model="phoneNum" class="note-input" placeholder="请输入手机号">
      </Input>
      <div slot="footer" class="footer">
        <Button type="primary" @click="compile_ok">确认</Button>
        <Button @click="closeModl">取消</Button>
      </div>
    </Modal>

    <div class="qr" v-show="bigqr">
      <Icon
        type="md-close-circle"
        @click="bigqr = false;shareModel=true"
        color="#fff"
        size="40"
      />
      <vue-qr
        :text="shareUrl"
        colorDark="#000"
        colorLight="#fff"
        style="display: block"
        :size="400"
      ></vue-qr>
    </div>
  </div>
</template>

<script>
let _videoId = 0
import Api from '@/utils/api.js'
import videoFullscreen from '@/assets/img/statistics/videoFullscreen.png'
import videoScreenRestore from '@/assets/img/statistics/videoScreenRestore.png'
import videoIndependent from '@/assets/img/statistics/videoIndependent.png'
import vueQr from 'vue-qr'
import Socket from '@/utils/socket.mixin.js'
export default {
  components: { vueQr },
  name: 'video-item',
  mixins: [Socket],
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    isWatch: {
      type: Boolean,
      default: () => false,
    },
    showCtrl: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    channel: {
      type: Number,
      default: 1,
    },
    independent: {
      type: Boolean,
      default: () => false,
    },
    switchUrl: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      spinShow: true,
      playUrl: '',
      fullScreen: false,
      videoFullscreen,
      videoScreenRestore,
      videoIndependent,
      player: null,
      shareModel: false,
      shareUrl: '',
      sharebaseUrl: '',
      liveNvrUrl: '',
      bigqr: false,
      shareNote: false, //短信分享弹窗
      phoneNum: '', //分享手机号
      changeLiveState: 1,
      user_info: {},
      radioNum:'0'
    }
  },
  mounted() {
    this.init(this.switchUrl)
    var _this = this
    this.sharebaseUrl = this.$mainHost + '/video.html?flv='
    document.addEventListener('fullscreenchange', function () {
      if (document.fullscreenElement != null) {
        console.info('Went full screen')
        _this.fullScreen = true
      } else {
        console.info('Exited full screen')
        _this.fullScreen = false
      }
    })
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
  },
  watch: {
    config(val) {
      if (this.isWatch) {
        this.init(this.switchUrl)
      }
    },
    fullScreen(status) {
      // console.log(status);
      // console.log(document.exitFullscreen);
      if (status) {
        var ele = this.$refs.live_video
        // var ele=document.getElementById('live-video');
        if (ele.requestFullscreen) {
          ele.requestFullscreen()
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen()
        } else if (ele.webkitRequestFullScreen) {
          ele.webkitRequestFullScreen()
        }
      } else {
        var de = document
        if (de.exitFullscreen) {
          de.exitFullscreen()
        } else if (de.mozCancelFullScreen) {
          de.mozCancelFullScreen()
        } else if (de.webkitCancelFullScreen) {
          de.webkitCancelFullScreen()
        }
      }
    },
    // FullscreenStatus(val) {
    //   console.log('FullscreenStatus', val);
    // },
  },
  computed: {
    videoId() {
      return 'live_video_' + _videoId++
    },
    // FullscreenStatus() {
    //   return (
    //     document.fullscreenElement ||
    //     document.msFullscreenElement ||
    //     document.mozFullScreenElement ||
    //     document.webkitFullscreenElement ||
    //     false
    //   );
    // },
  },

  methods: {

    radioNumBut(e){
      console.log(e)
      this.radioNum=e
    },
    // 连接socket
    connectSocket() {
      let param = {}
      const url = Api.getWebSocketUrl() + this.userInfo.user_id
      this.connectWebsocket(url, param, this.socketMsgHandler)
    },
    // 初始化video
    init(type) {
      // console.log('init')
      var _this = this
      let url = Api.getLiveUrl()
      if (!(this.config.IsPublish || this.config.is_publish)) {
        return
      }
      // if (!this.config.IsPublish) {
      //   this.connectSocket()
      // }
      if (!this.$online) {
        url = Api.getLiveqingUrl()
        if (this.$CSTJ) {
          url = Api.getLiveNvr()
        }
      }
      console.log('url====',url);
      this.$post(url, {
        client: 'web',
        channel: this.channel,
        user_id: this.config.user_id,
      })
        .then((res) => {
          if (res.data) {
            this.shareUrl = this.sharebaseUrl + res.data.flv_url

           // this.shareUrl ='http://192.168.0.173:8020/texts/video.html?flv=' + res.data.flv_url

           /* console.log('111111111推流地址111111111',res.data.flv_url)
            console.log('1111111111地址11111111',this.shareUrl)*/
            if (this.$online) {
              if (this.player !== null) {
                this.player.destroy()
                this.player = null
              }
              let liveType = type
              if (liveType === 2) {
                console.log('diyanchi')
                // webrtc低延迟直播
                this.player = new TcPlayer(this.videoId, {
                  // "m3u8": res.data.m3u8_url,
                  webrtc: res.data.UDP_url,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          )
                          break
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。')
                          break
                        case 3:
                          _this.$message.error('视频解码错误。')
                          break
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          )
                          break
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          )
                          break
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。')
                          break
                        case 1001:
                          _this.$message.error('断网了')
                          break
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          )
                          break
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          )
                          break
                        default:
                          _this.$message.error('出错了')
                          break
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束')
                        _this.player.destroy()
                        _this.player = null
                        _this.$_bus.$emit('endLive')
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                })
              } else if (liveType === 1) {
                console.log('gaoyanchi')
                // 普通flv直播
                this.player = new TcPlayer(this.videoId, {
                  // m3u8: res.data.m3u8_url,
                  flv: res.data.flv_url,
                  h5_flv: true,
                  //   rtmp: res.data.default_url,
                  live: true,
                  //   controls: 'none',
                  volume: 0,
                  autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                  width: '100%', //视频的显示宽度
                  height: '100%', //视频的显示高度
                  listener: function (msg) {
                    if (msg.type == 'error') {
                      switch (msg.detail.code) {
                        case 1:
                          _this.$message.error(
                            '网络错误，请检查网络配置或者播放链接是否正确。'
                          )
                          break
                        case 2:
                          _this.$message.error('视频格式 Web 播放器无法解码。')
                          break
                        case 3:
                          _this.$message.error('视频解码错误。')
                          break
                        case 4:
                          _this.$message.error(
                            '当前系统环境不支持播放该视频格式。'
                          )
                          break
                        case 5:
                          _this.$message.error(
                            '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
                          )
                          break
                        case 13:
                          _this.$message.error('直播已结束，请稍后再来。')
                          break
                        case 1001:
                          _this.$message.error('断网了')
                          break
                        case 1002:
                          _this.$message.error(
                            '获取视频失败，请检查播放链接是否有效。'
                          )
                          break
                        case 2048:
                          _this.$message.error(
                            '无法加载视频文件，跨域访问被拒绝。'
                          )
                          break
                        default:
                          _this.$message.error('出错了')
                          break
                      }
                    }

                    //   if (msg.type == 'pause') {
                    //     console.log('pause');
                    //   }
                    if (msg.type == 'ended') {
                      if (_this.independent) {
                        _this.$Message.info('小窗直播已结束')
                        _this.player.destroy()
                        _this.player = null
                        _this.$_bus.$emit('endLive')
                      }
                    }
                    //   if (msg.type == 'seeking') {
                    //     console.log('seeking');
                    //   }
                    //   if (msg.type == 'seeked') {
                    //     console.log('seeked');
                    //   }
                    //   if (msg.type == 'resize') {
                    //     console.log('resize');
                    //   }
                  },
                })
              }
              // } else {
              //   console.log('gaoyanchi')
              //   // 普通flv直播
              //   this.player = new TcPlayer(this.videoId, {
              //     "m3u8": res.data.m3u8_url,
              //     // flv: res.data.flv_url,
              //     h5_flv: true,
              //     //   rtmp: res.data.default_url,
              //     live: true,
              //     //   controls: 'none',
              //     volume: 0,
              //     autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
              //     width: '100%', //视频的显示宽度
              //     height: '100%', //视频的显示高度
              //     listener: function (msg) {
              //       if (msg.type == 'error') {
              //         switch (msg.detail.code) {
              //           case 1:
              //             _this.$message.error(
              //               '网络错误，请检查网络配置或者播放链接是否正确。'
              //             )
              //             break
              //           case 2:
              //             _this.$message.error('视频格式 Web 播放器无法解码。')
              //             break
              //           case 3:
              //             _this.$message.error('视频解码错误。')
              //             break
              //           case 4:
              //             _this.$message.error(
              //               '当前系统环境不支持播放该视频格式。'
              //             )
              //             break
              //           case 5:
              //             _this.$message.error(
              //               '播放器判断当前浏览器环境不支持播放传入的视频，可能是当前浏览器不支持 MSE 或者 Flash 插件未启用。'
              //             )
              //             break
              //           case 13:
              //             _this.$message.error('直播已结束，请稍后再来。')
              //             break
              //           case 1001:
              //             _this.$message.error('断网了')
              //             break
              //           case 1002:
              //             _this.$message.error(
              //               '获取视频失败，请检查播放链接是否有效。'
              //             )
              //             break
              //           case 2048:
              //             _this.$message.error(
              //               '无法加载视频文件，跨域访问被拒绝。'
              //             )
              //             break
              //           default:
              //             _this.$message.error('出错了')
              //             break
              //         }
              //       }

              //       //   if (msg.type == 'pause') {
              //       //     console.log('pause');
              //       //   }
              //       if (msg.type == 'ended') {
              //         if (_this.independent) {
              //           _this.$Message.info('小窗直播已结束')
              //           _this.player.destroy()
              //           _this.player = null
              //           _this.$_bus.$emit('endLive')
              //         }
              //       }
              //       //   if (msg.type == 'seeking') {
              //       //     console.log('seeking');
              //       //   }
              //       //   if (msg.type == 'seeked') {
              //       //     console.log('seeked');
              //       //   }
              //       //   if (msg.type == 'resize') {
              //       //     console.log('resize');
              //       //   }
              //     },
              //   })
              // }

              // if (this.player !== null) {
              //   this.player.videoClear();
              //   this.player = null;
              // }
              // let videoObject = {
              //   container: '#' + this.videoId, //容器的ID或className
              //   variable: 'player', //播放函数名称
              //   autoplay: true,
              //   live: true,
              //   video: res.data.default_url,
              //   loaded: 'loadedHandler', //当播放器加载后执行的函数
              // };
              // this.player = new ckplayer(videoObject);
            } else {
              // $CSTJ
              this.liveNvrUrl =
                'http://43.4.201.100:10800/play.html?channel=' +
                res.data.channel +
                '&iframe=yes&aspect=741x420'
              // ========
              ///////
              /**
               * flv.js
               * 离线的flv播放器
               * 不依赖flash
               * 目前作为通用flv播放器,取代tcplayer
               */
              // if (this.player !== null) {
              //   this.player.pause();
              //   this.player.unload();
              //   this.player.detachMediaElement();
              //   this.player.destroy();
              //   this.player = null;
              // }
              // this.$nextTick(() => {
              //   if (flvjs.isSupported()) {
              //     this.player = flvjs.createPlayer({
              //       type: 'flv',
              //       url: res.data.WSFLV,
              //       isLive: true,
              //     });
              //     this.player.attachMediaElement(
              //       document.getElementById(_this.videoId)
              //     );
              //     this.player.load();
              //     // 监听直播流状态.如果小窗在直播流断开时,则关闭小窗
              //     // this.player.play();
              //     //   if (this.independent) {
              //     this.player.on(
              //       flvjs.Events.ERROR,
              //       (errorType, errorDetail, errorInfo) => {
              //         console.log('errorType', errorType);
              //         console.log('errorDetail', errorDetail);
              //         console.log('errorInfo', errorInfo);
              //       }
              //     );
              //     this.player.on('error', (err) => {
              //       console.log('err', err);
              //     });
              //     this.player.on('ERROR', (err) => {
              //       console.log('ERROR', err);
              //     });
              //     //   }
              //   } else {
              //     this.$message.error('不支持flv格式');
              //     return;
              //   }
              // });
              // ========
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notice.error({
            title: '获取直播地址失败,返回无数据',
            desc: err.toString(),
          })
        })
        .finally(() => {
          this.spinShow = false
        })
    },
    // 云台控制
    remoteControlHandler(type, val) {
      let parems = {
        dataType: type,
        fromId: JSON.parse(sessionStorage.getItem('user_info')).user_id,
        frpmName: JSON.parse(sessionStorage.getItem('user_info')).name,
        pX: 0,
        pY: 0,
        targetId: this.config.user_id,
        toName: this.config.name,
      }
      if (val == 'up') {
        parems.pY = 2
      } else if (val == 'down') {
        parems.pY = -2
      } else if (val == 'left') {
        parems.pX = -2
      } else if (val == 'right') {
        parems.pX = 2
      }
      this.$post(Api.controlPTZ(), parems)
        .then((res) => {
          //   this.$Message.success(res.msg_customer);
        })
        .catch((err) => {
          this.$Message.error(err)
        })
    },
    toIndependent() {
      this.$_bus.$emit('showLiveHandler', this.config)
    },
    copy() {
      //   console.log(params.row.apk_url);
      let oInput = document.createElement('input')
      oInput.value = this.shareUrl
      document.body.appendChild(oInput)
      oInput.select() // 选择对象
      document.execCommand('Copy') // 执行浏览器复制命令
      oInput.style.display = 'none'
      this.$Message.success('已复制到剪切板')
      document.body.removeChild(oInput)
    },
    //提交手机号
    compile_ok() {
      if (this.phoneNum) {
        const pattern =
          /[a-zA-Z`%~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！!@#￥……&*（）——|{}【】'；：""'。，、？\s]/g
        let repNum = this.phoneNum.replace(pattern, ',')
        let partitionNum = repNum.split(',')
        this.shareNote = false
        this.shareModel=false
        let params = {
          userIdList: partitionNum,
          livePath: this.shareUrl,
        }
        this.$post(Api.shareNote(), params)
      } else {
        this.$Message.error('请输入手机号')
      }
    },
    //关闭分享弹窗
    closeModl() {
      this.shareNote = false
      this.shareModel=false
    },
    //关闭分享弹窗，打开短信风向弹窗
    showNote() {
      this.shareModel = false
      this.shareNote = true
    },
  },
  beforeDestroy() {
    if (this.$online || this.liveNvrUrl != '') {
      if (this.player) {
        this.player.destroy()
      }

      this.player = null
    } else {
      this.player.pause()
      this.player.unload()
      this.player.detachMediaElement()
      ////
      this.player.destroy()
      this.player = null
      /////
      //   this.player.videoClear();
    }
  },
}
</script>

<style lang="scss" scoped>
.live-video {
  width: 100%;
  height: 100%;
  position: relative;
  .video {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
  }
  .FullscreenIcon {
    position: absolute;
    top: 30px;
    right: 60px;
    width: 26px;
    height: auto;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
  .FullscreenIcon-independent {
    position: absolute;
    top: 12px;
    left: 13px;
    cursor: pointer;
  }
  .share {
    position: absolute;
    top: 28px;
    right: 10px;
    height: auto;
    font-size: 34px;
    color: #fff;
    opacity: 0;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
  .share-note {
    display: flex;
    display: inline;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    border: 1px solid $xf_hui7;
    color: #c9caca;
    background-color: $xf_hui6;
    cursor: pointer;
  }

  .footer {
    text-align: right;
    button:last-child {
      background-color: $xf_hui7;
      color: $font_color_1;
    }
    button:last-child:hover {
      background-color: $xf_hui7_hover;
    }
  }
  .qr {
    position: fixed;
    width: 100vw;
    height: 100vw;
    top: 0;
    left: 0;
    z-index: 1800;
    background-color: rgba(0, 0, 0, 0.7);
    img {
      margin: 2rem auto 0;
    }
    i {
      position: absolute;
      top: 0.15rem;
      right: 0.15rem;
      cursor: pointer;
    }
  }
  .control {
    width: 180px;
    height: 120px;
    border-radius: 60px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 5%;
    bottom: 10%;
    opacity: 0;
    z-index: 100;
    transition: all 0.5s;
    transform: scale(0.8);
    .four-direction {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      background-color: #212121;
      position: relative;
      //   right: 5%;
      //   bottom: 10%;
      //   opacity: 0;
      //   z-index: 100;
      //   transition: all 0.5s;
      .center-circle {
        background-color: #afafaf;
        top: 56px;
        left: 56px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        margin-left: -20px;
        margin-top: -20px;
        z-index: 5;
      }
      .center-line {
        width: 112px;
        height: 5px;
        margin-top: -2px;
        background-color: #000;
        position: absolute;
        top: 56px;
        left: 0;
      }
      .rotate-clockwise {
        transform: rotate(45deg);
      }
      .rotate-anti-clockwise {
        transform: rotate(-45deg);
      }
      .control-direction-arrow {
        color: #afafaf;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
      }
      .icon-top {
        top: 18px;
        margin-top: -24px;
        left: 56px;
        margin-left: -24px;
      }
      .icon-bottom {
        bottom: 18px;
        margin-bottom: -24px;
        left: 56px;
        margin-left: -24px;
        i {
          transform: rotate(180deg);
        }
      }
      .icon-right {
        top: 56px;
        margin-top: -24px;
        right: 18px;
        margin-right: -24px;
        i {
          transform: rotate(90deg);
        }
      }
      .icon-left {
        top: 56px;
        margin-top: -24px;
        left: 18px;
        margin-left: -24px;
        i {
          transform: rotate(-90deg);
        }
      }
    }
    .zoom {
      margin-left: 10px;
    }
  }
}
.live-video:hover {
  .FullscreenIcon {
    opacity: 1 !important;
  }
  .share {
    opacity: 1 !important;
  }
  .control {
    opacity: 0.7 !important;
  }
}
.shareUrl {
  //   p {
  //     width: 400px;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
  //   }
  .share-span {
    display: inline-block;
    border: 1px solid $xf_hui7;
    padding: 0.05rem;
    background-color: $xf_hui6;
    margin-right: 0.12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  margin-bottom: 0.2rem;
}
</style>
